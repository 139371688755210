import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { observer } from "mobx-react";
import Client from "gqlclient/Client";
import { useMutation } from "@apollo/client";
import { Input, Button, Spinner, Modal } from "@tecma/ds";
import { useTranslation } from "@tecma/i18n";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Cookies from "js-cookie";
import psl from "psl";

import { useStore } from "store/storeUtils";
import { HomeRoute, ChangePasswordRoute } from "routes/Routes";
import FollowupFormOtp from "components/specific/FollowupFormOtp";
import { LoginWrapper } from "components/LoginWrapper";
import { getParamByName, isValidBackToParam } from "utils/urlParamUtils";
import convertLanguage from "utils/convertLanguage";
import { ERROR_CODES } from "constants/errors";

import "styles/login.scss";
import "react-simple-keyboard/build/css/index.css";
import { getErrorName } from "./utils";

const EMAIL_REGEX = RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i,
);

/**
 * Pagina di login
 * @component
 */
const Login = observer(() => {
  const store = useStore();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [retry, setRetry] = React.useState(false);

  // The following state is to trigger the ErrorBoundary when connection is missing
  const [error, setError] = React.useState(null);
  if (error) {
    throw error;
  }
  const [doLogin, loginData] = useMutation(Client.LOGIN, {
    onError: (err) => {
      if (err.message === "Failed to fetch") setError(err);
    },
  });
  const doneCalling = loginData && loginData.called && !loginData.loading;

  const initialValues = { email: "", password: "" };

  const [errorModalClient, setErrorModalClient] = React.useState(false);
  const [errorModal, setErrorModal] = React.useState(false);
  const [globalLoaderVal, setGlobalLoaderVal] = React.useState(false);
  const [layoutName, setLayoutName] = React.useState("default");
  const [keyboard, setKeyboard] = React.useState(null);
  const [shift, setShift] = React.useState(false);
  const [keyboardFocus, setKeyBoardFocus] = React.useState("email");
  const [expired, setExpired] = React.useState(false);
  const [userDisabled, setUserDisabled] = React.useState(false);
  const [expiredPasswordModal, setExpiredPasswordModal] = React.useState(false);
  const [changePasswordToken, setChangePasswordToken] = React.useState("");
  const [hostKey, setHostKey] = React.useState("");
  const [isPCTuser, setIsPCTuser] = React.useState(false);
  const [TwoFA, setTwoFA] = React.useState(false);
  const [keyboardVisibleOtp, setKeyboardVisibleOtp] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    mode: "onBlur",
  });

  const email = watch("email");
  const password = watch("password");

  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true,
  });
  let TempTwoFA = false;
  const [canLogin, setCanLogin] = React.useState(1);
  // Cerco il domain da impostare nel cookie
  // second level domain + top level domain (es. 'tecmasolutions.com')
  let domain = "localhost";
  if (window.location.hostname !== "localhost") {
    const pslUrl = psl.parse(window.location.hostname);
    domain = pslUrl.domain;
  }

  const cookieOptions = {
    expires: 1, // durata di 1 giorno, se non viene messa la durata diventa un session cookie che viene eliminato dopo aver chiuso il browser
    domain: domain,
  };

  const loggedInStuff = (data) => {
    if (!errorModalClient || !errorModal) {
      if (
        doneCalling &&
        loginData.data &&
        loginData.data.loginByProjectBP &&
        loginData.data.loginByProjectBP.token.accessToken === "" &&
        loginData.data.loginByProjectBP.user.TwoFA &&
        canLogin > 0
      ) {
        //variabile a true per la modale
        setTwoFA(true);
        TempTwoFA = true;
      }

      store.setLoggedUser(data.user);
      if (!data?.user?.TwoFA && i18n.language.split("-")[0] !== data?.user?.language) {
        i18n.changeLanguage(convertLanguage(data?.user?.language));
      }
      store.setModalOtp(false);
      if (data.token && data.token.accessToken) {
        // Salvo jwt token e vendorId nel cookie di dominio
        Cookies.set("jwt", data.token.accessToken, cookieOptions);
        Cookies.set("refreshToken", data.token.refreshToken, cookieOptions);
        Cookies.set("expiresIn", data.token.expiresIn, cookieOptions);
        Cookies.set("vendorId", data.user.id, cookieOptions);
        Cookies.set("userLanguage", data.user.language, cookieOptions);

        // Se siamo tornati su businessplatform solo per il login
        if (canLogin > 0 && !TempTwoFA) {
          const backTo = getParamByName("backTo");
          if (backTo && isValidBackToParam(backTo)) {
            window.location = decodeURIComponent(backTo);
          }
          history.push(HomeRoute.to);
        }
      }
    }
  };

  useEffect(() => {
    if (history.location.state?.error === "userDisabled") {
      setErrorModal(true);
      setUserDisabled(true);
    }
  }, []);

  useEffect(() => {
    const errorName = getErrorName(loginData.error);
    if (doneCalling && errorName === ERROR_CODES.WRONG_ROLE) {
      setErrorModal(true);
      setErrorModalClient(true);
    } else if (loginData?.error?.message === "Expired") {
      setErrorModal(true);
      setExpired(true);
    } else if (doneCalling && errorName === ERROR_CODES.USER_DISABLED) {
      setErrorModal(true);
      setUserDisabled(true);
    } else if (
      doneCalling &&
      loginData?.data?.loginByProjectBP?.token?.tokenType === "ExpiredPassword"
    ) {
      openExpiredPassModal(
        loginData.data.loginByProjectBP.token.expiredToken,
        loginData.data.loginByProjectBP.token.hostKey,
        true,
        loginData.data.loginByProjectBP.isPCTuser,
      );
    } else if (loginData?.data?.loginByProjectBP) {
      loggedInStuff(loginData.data.loginByProjectBP);
    }
    // eslint-disable-next-line
  }, [doneCalling]);

  const openExpiredPassModal = (expiredToken, hostKey, modal, isPCTuser) => {
    setChangePasswordToken(expiredToken);
    setHostKey(hostKey);
    setIsPCTuser(isPCTuser);
    setExpiredPasswordModal(modal);
  };

  if (
    doneCalling &&
    (!loginData.data || !loginData.data.loginByProjectBP) &&
    !errorModal &&
    !retry
  ) {
    setErrorModal(true);
  }

  const onSubmit = async (data) => {
    setCanLogin(1);
    if (errors.email) {
      return;
    }
    setRetry(false);
    setErrorModal(false);
    setErrorModalClient(false);
    setExpired(false);
    setUserDisabled(false);
    if (!TwoFA) {
      const loginResponse = await doLogin({
        variables: {
          input: {
            email: data.email,
            password: data.password,
            project_id: store.project_id,
          },
        },
      });
      if (
        loginResponse.data &&
        loginResponse.data.loginByProjectBP &&
        loginResponse.data.loginByProjectBP.otpValidationKey
      ) {
        if (store.keyboardVisible) {
          setKeyboardVisibleOtp(store.keyboardVisible);
        }
      }
    }
  };

  const handleLogin = async () => {
    store.setSnackbarError(false);
    await doLogin({
      variables: {
        input: {
          email,
          password,
          project_id: store.project_id,
        },
      },
    });
  };

  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
    setShift(!shift);
  };
  const handleLock = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  const handleClear = () => {
    if (keyboard) {
      keyboard.clearInput();
    }
  };
  const handleTwoFA = () => {
    setCanLogin(-1);
    TempTwoFA = false;
    setTwoFA(false);
  };

  const keyboardProps = {
    initialValues,
    formValues: {
      email,
      password,
    },
    setFormValues: (newValues) => {
      setValue("email", newValues.email);
      setValue("password", newValues.password);
    },
    keyboardFocus,
    setKeyBoardFocus,
    onSubmit,
    keyboardFields: {
      email: {},
      password: {},
    },
  };

  const errorMessage =
    !expired && !userDisabled
      ? "businessplatform.accessFail"
      : expired && !userDisabled
      ? "businessplatform.loggedUserExpired"
      : !expired && userDisabled
      ? "businessplatform.loggedUserDisabled"
      : "";

  const loading = globalLoaderVal || (loginData && loginData.called && loginData.loading);

  return (
    <LoginWrapper className='login-page' keyboardProps={keyboardProps}>
      <LoginWrapper.Header title='businessplatform.login' />
      <LoginWrapper.Content
        onSubmit={handleSubmit(onSubmit)}
        showAlert={errorModal}
        alertProps={{ title: errorMessage }}
      >
        <Modal isOpen={expiredPasswordModal} onClose={() => setExpiredPasswordModal(false)}>
          <Modal.Header closeIcon>
            <h2>{t("businessplatform.expiredPassword")}</h2>
          </Modal.Header>
          <div className='divider' />
          <Modal.Content>
            <h3>{t("businessplatform.expiredPassword.title")}</h3>
            <p>{t("businessplatform.expiredPassword.description")}</p>
          </Modal.Content>
          <Modal.Footer>
            <Button
              onClick={() => {
                history.push(ChangePasswordRoute(hostKey, changePasswordToken, isPCTuser));
              }}
            >
              {t("businessplatform.changePassword.resetPassword")}
            </Button>
          </Modal.Footer>
        </Modal>
        <Input
          id='email'
          label={t("businessplatform.login.email")}
          placeholder={t("businessplatform.login.placeholder.email")}
          name='email'
          className='login-wrapper-content-input'
          type='email'
          status={(errorModal || errors.email) && "error"}
          helpText={
            errors.email &&
            (errors.email.type === "required"
              ? t("businessplatform.resetPassword.field-required")
              : errors.email.type === "pattern"
              ? t("businessplatform.validMail")
              : "error")
          }
          {...register("email", {
            required: true,
            onClick: () => {
              setKeyBoardFocus("email");
            },
            onChange: (e) => {
              store.updateVendorEmail(e.target.value);
            },
            pattern: EMAIL_REGEX,
          })}
        />
        <Input
          id='password'
          label={t("businessplatform.login.password")}
          placeholder={t("businessplatform.login.placeholder.password")}
          name='password'
          type='password'
          className='login-wrapper-content-input'
          status={(errorModal || errors.password) && "error"}
          helpText={
            errors.password?.type === "required"
              ? t("businessplatform.resetPassword.field-required")
              : null
          }
          {...register("password", {
            required: true,
            onClick: () => {
              setKeyBoardFocus("password");
            },
          })}
        />
        <div className='forgot-password-wrapper'>
          <Link to='/forgotPassword' className='forgot-password'>
            <span>{t("businessplatform.forgotPassword")}</span>
          </Link>
        </div>
        <Button color='primary' className='login-button' type='submit' fluid>
          {loading && <Spinner />}
          {t("businessplatform.login")}
        </Button>

        {TwoFA && loginData.data && loginData.data.loginByProjectBP && (
          <FollowupFormOtp
            globalLoaderVal={globalLoaderVal}
            setGlobalLoaderVal={setGlobalLoaderVal}
            email={store.vendorEmail}
            password={password}
            otpValidationKeyBE={loginData.data.loginByProjectBP.otpValidationKey}
            handleLogin={handleLogin}
            handleTwoFA={handleTwoFA}
            openExpiredPassModal={openExpiredPassModal}
            layoutName={layoutName}
            setKeyboard={setKeyboard}
            setKeyboardVisibleOtp={setKeyboardVisibleOtp}
            keyboardVisibleOtp={keyboardVisibleOtp}
            handleShift={handleShift}
            handleClear={handleClear}
            handleLock={handleLock}
            matchesPhone={matchesPhone}
          />
        )}
      </LoginWrapper.Content>
    </LoginWrapper>
  );
});

export default Login;
