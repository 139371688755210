import React from "react";
import { observer } from "mobx-react";
import { useMutation } from "@apollo/client";
import Client from "gqlclient/Client";
import { useStore } from "store/storeUtils";
import { useTranslation } from "@tecma/i18n";
import { logger } from "@tecma/logs-fe";
import { Button, Checkbox, Modal } from "@tecma/ds";

const LoginOtp = observer(() => {
  const store = useStore();
  const { t } = useTranslation();

  const [changeValue] = useMutation(Client.UPDATE_FIRSTLOGIN);
  const [setTwoFA, { loading }] = useMutation(Client.UPDATE_TwoFA);
  const [check, setCheck] = React.useState(false);

  const handleModal = () => {
    changeValue(
      Client.UPDATE_FIRSTLOGIN_DEFAULT_OPTIONS(store.projectId, store.loggedUser.id, !check),
    )
      .then(() => {
        store.setModalOtp(true);
        store.setLoggedUserFirstLogin(!check);
      })
      .catch((e) => {
        logger.error(e);
        store.setSnackbarError(true, t("businessplatform.userUpdate"));
        store.setLoggedUserFirstLogin(true);
        store.setModalOtp(false);
      });
  };

  const handleTwoFA = () => {
    setTwoFA(Client.UPDATE_TwoFA_DEFAULT_OPTIONS(store.projectId, store.loggedUser.id, true))
      .then(() => {
        store.setLoggedUserTwoFA(true);
      })
      .catch((e) => {
        logger.error(e);
        store.setSnackbarError(true, t("businessplatform.otpActivation"));
        store.setLoggedUserTwoFA(false);
      });
  };

  return (
    <Modal isOpen>
      <Modal.Header
        title={t("businessplatform.otpActivationTitle")}
        onClose={() => {
          store.setModalOtp(true);
        }}
        isBackgroundDark
        closeIcon
      />
      <div className='divider' />
      <Modal.Content>
        <h3>{t("businessplatform.otpQuestion")}</h3>
        <p></p>
        <Checkbox
          checked={check}
          onChange={() => {
            setCheck(!check);
          }}
          label={t("businessplatform.dontShowMessage")}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button disabled={loading} onClick={handleModal} outlined>
          {t("businessplatform.resetPassword.label.no")}
        </Button>
        <Button disabled={loading} onClick={handleTwoFA} loader={loading && "circle"}>
          {t("businessplatform.resetPassword.label.yes")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default LoginOtp;
